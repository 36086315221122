<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'quarter'}" v-on:click="setTempPeriod('quarter')" ref="view_type_quarter"  data-toggle="pill" data-period="quarter">{{ $t('period.quarter') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-goals-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Vendedor -->
              <!--<h6 class="mb-3 mt-5">{{ $t('form.report.seller') }}</h6>-->
              <div v-if="showEmployeeFilter" class="mt-5">
                  <div class="md-form mb-0 ">
                    <div class="md-form mb-0">
                      <select class="md-form-control" :value="filters.filEmployee" id="employee" name="nome" ref="employee">
                        <option v-for="(item, index) in sellerList" v-bind:key="index" :value="item.id"  :selected="filters.filEmployee === item.id" >{{ item.nome }}</option>
                      </select>
                      <label :class="{'active': true}">{{ $t('form.report.seller') }}</label>
                    </div>
                  </div>
              </div>

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in reportData.years" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'quarter'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filQuarter" id="quarter" name="quarter" ref="quarter" >
                          <option v-for="(quarter, index) in reportData.quarters" v-bind:key="index" :value="index" :selected="filters.filQuarter === parseInt(index)" >{{ quarter }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.quarter') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in reportData.months" v-bind:key="index" :value="index" :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-10 mb-3 filter-container">
        <filter-container :activeFilterList="getActiveFilterList()" />
      </div>
      <div class="col-2 mb-3 action-buttons">
        <h5 data-v-73673569="" class="box-title mb-0 pb-2">&nbsp;</h5>
        <button v-on:click="sendNow()" class="btn btn-primary btn-sm btn-fill btn-block mr-2 "><i class="text-white  ft-send "></i> {{ $t('actions.send_now') }}</button>
      </div>

      <div class="col-12 col-md-12 report-content">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="gray-box">
              <div class="row seller-box">
                <div class="seller  col-sm-12 col-md-offset-5">
                  <img :src="generateImage(seller.image)">
                  <h2>{{ seller.name }}, {{ seller.role }}</h2>
                </div>
              </div>

              <div class="row summary-box">
                <div class="col-12 message-box">
                  <div class="message col-12 text-center">
                    {{ getTitle() }}
                  </div>
                </div>
                <div class="col-12 mt-4" v-show="showNewChart">
                  <div class="card-title">
                    <div class="small">Total Recebido</div>R$ {{ (reportData.summary) ? showNumber(reportData.summary.amount.current) : '0,00' }}<br/>
                  </div>
                  <horizontal-gauge-chart :ranges="((reportData.amount) ? reportData.amount.goal : null)"  :value="((reportData.summary) ? reportData.summary.amount.current : 0)" />
                </div>

                <div class="col-12" v-if="filters.filEmployee && filters.filEmployee !== null" v-show="showNewChart">
                  <horizontal-gauge-chart title="Média Geral" :ranges="((reportData.amount) ? reportData.amount.goal : null)" :value="((reportData.summary) ? reportData.summary.amount_average.current : 0)" />
                </div>
                <div class="col-12">
                  <div class="row justify-content-md-center">
                    <!-- FCL TEUS -->
                    <card classRoot="col-lg-3 col-md-6 col-xs-12">
                      <template v-slot:title>
                        {{ (reportData.summary) ? showNumber(reportData.summary.maritime.fcl.current) : 0 }} / {{ (reportData.summary) ? showNumber(reportData.summary.maritime.fcl.goal) : 0 }}
                      </template>

                      <template>
<!--                        <solid-donut-chart :value="(reportData.summary) ? reportData.summary.maritime.fcl.current * 100.0 / reportData.summary.maritime.fcl.goal : 0.0" />-->
                        <donnut-chart
                          elementId="chart-maritime-fcl-boarded"
                          indicator="teste"
                          :value="(reportData.summary) ?reportData.summary.maritime.fcl.current : 0.0"
                          :goal="(reportData.summary) ? (reportData.summary.maritime.fcl.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.summary.maritime.fcl)) : 0"
                          width="150px"
                          height="150px"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
    <!--                      <i class="rc-fcl font-40 text-primary"></i>-->
                          FCL
                        </donnut-chart>

                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-maritime-fcl-boarded"
                            :value="(reportData.summary) ? reportData.summary.maritime.fcl.goalPerMonth : []"
                            width="150px"
                            height="50px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                        </div>
                      </template>

                      <template v-slot:legend>
                        <div class="small mb-2">
                          <b>{{ showNumber((reportData.summary) ? reportData.summary.maritime.fcl.total_realized : 0.00, '.') }}</b>
                          / {{ showGoal(((reportData.summary) ? (reportData.summary.maritime.fcl.total_goal) : 0), '.', '-') }}
                        </div>
                        TEUS<br/><span class="small"><b>IM</b>PO & <b>EX</b>PO</span>
                      </template>
                    </card>

                    <!-- LCL FILES -->
                    <card classRoot="col-lg-3 col-md-6 col-xs-12">
                      <template v-slot:title>
                        {{ (reportData.summary) ? showNumber(reportData.summary.maritime.lcl.current) : 0 }} / {{ (reportData.summary) ? showNumber(reportData.summary.maritime.lcl.goal) : 0 }}
                      </template>
                      <template>
                        <donnut-chart
                          elementId="chart-maritime-lcl-boarded"
                          :value="(reportData.summary) ?reportData.summary.maritime.lcl.current : 0.0"
                          :goal="(reportData.summary) ? (reportData.summary.maritime.lcl.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.summary.maritime.lcl)) : 0"
                          width="150px"
                          height="150px"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
    <!--                      <i class="rc-lcl font-40 text-primary"></i>-->
                          LCL
                        </donnut-chart>
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-maritime-lcl-boarded"
                            :value="(reportData.summary) ? reportData.summary.maritime.lcl.goalPerMonth : []"
                            width="150px"
                            height="50px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                        </div>
                      </template>

                      <template v-slot:legend>
                        <div class="small mb-2">
                          <b>{{ showNumber((reportData.summary) ? reportData.summary.maritime.lcl.total_realized : 0.00, '.') }}</b>
                          / {{ showGoal(((reportData.summary) ? (reportData.summary.maritime.lcl.total_goal) : 0), '.', '-') }}
                        </div>
                        PROCESSOS<br/><span class="small"><b>IM</b>PO & <b>EX</b>PO</span>
                      </template>
                    </card>

                    <!-- CABOTAGEM FILES -->
                    <card classRoot="col-lg-3 col-md-6 col-xs-12" v-show="showNewChart" >
                      <template v-slot:title>
                        {{ (reportData.summary) ? showNumber(reportData.details.cabotagem.boarded.files.current) : 0 }} / {{ (reportData.summary) ? showNumber(reportData.details.cabotagem.boarded.files.goal) : 0 }}
                      </template>
                      <template>
                        <donnut-chart
                          elementId="chart-maritime-cabotagem-boarded"
                          :value="(reportData.details) ? reportData.details.cabotagem.boarded.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.cabotagem.boarded.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.cabotagem.boarded.files)) : 0"
                          width="150px"
                          height="150px"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
                          <!--                      <i class="rc-lcl font-40 text-primary"></i>-->
                          CABOTAGEM
                        </donnut-chart>
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-maritime-cabotagem-boarded"
                            :value="(reportData.details) ? reportData.details.cabotagem.boarded.files.goalPerMonth : []"
                            width="150px"
                            height="50px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                        </div>
                      </template>

                      <template v-slot:legend>
                        <div class="small mb-2">
                          <b>{{ showNumber((reportData.details) ? reportData.details.cabotagem.boarded.files.total_realized : 0.00, '.') }}</b>
                          / {{ showGoal(((reportData.details) ? (reportData.details.cabotagem.boarded.files.total_goal) : 0), '.', '-') }}
                        </div>
                        PROCESSOS<br/><span class="small"><b>NAC</b>IONAL</span>
                      </template>
                    </card>
                  </div>

                  <div class="row justify-content-md-center" v-show="showNewChart">
                    <!-- RODOVIARIO IMPO & EXPO FILES -->
                    <card classRoot="col-lg-3 col-md-6 col-xs-12">
                      <template v-slot:title>
                        {{ (reportData.summary) ? showNumber(reportData.details.land.internacional.boarded.files.current) : 0 }} / {{ (reportData.summary) ? showNumber(reportData.details.land.internacional.boarded.files.goal) : 0 }}
                      </template>
                      <template>
                        <donnut-chart
                          elementId="chart-land-internacional-boarded"
                          :value="(reportData.details) ? reportData.details.land.internacional.boarded.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.land.internacional.boarded.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.land.internacional.boarded.files)) : 0"
                          width="150px"
                          height="150px"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
                          <!--                      <i class="rc-lcl font-40 text-primary"></i>-->
                          RODOVIÁRIO
                        </donnut-chart>
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-land-internacional-boarded"
                            :value="(reportData.details) ? reportData.details.land.internacional.boarded.files.goalPerMonth : []"
                            width="150px"
                            height="50px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                        </div>
                      </template>

                      <template v-slot:legend>
                        <div class="small mb-2">
                          <b>{{ showNumber((reportData.details) ? reportData.details.land.internacional.boarded.files.total_realized : 0.00, '.') }}</b>
                          / {{ showGoal(((reportData.details) ? (reportData.details.land.internacional.boarded.files.total_goal) : 0), '.', '-') }}
                        </div>
                        PROCESSOS<br/><span class="small"><b>IM</b>PO & <b>EX</b>PO</span>
                      </template>
                    </card>

                    <!-- RODOVIARIO Nacional FILES -->
                    <card classRoot="col-lg-3 col-md-6 col-xs-12">
                      <template v-slot:title>
                        {{ (reportData.summary) ? showNumber(reportData.details.land.nacional.boarded.files.current) : 0 }} / {{ (reportData.summary) ? showNumber(reportData.details.land.nacional.boarded.files.goal) : 0 }}
                      </template>
                      <template>
                        <donnut-chart
                          elementId="chart-land-nacional-boarded"
                          :value="(reportData.details) ? reportData.details.land.nacional.boarded.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.land.nacional.boarded.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.land.nacional.boarded.files)) : 0"
                          width="150px"
                          height="150px"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
                          <!--                      <i class="rc-lcl font-40 text-primary"></i>-->
                          RODOVIÁRIO
                        </donnut-chart>
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-land-nacional-boarded"
                            :value="(reportData.details) ? reportData.details.land.nacional.boarded.files.goalPerMonth : []"
                            width="150px"
                            height="50px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                        </div>
                      </template>

                      <template v-slot:legend>
                        <div class="small mb-2">
                          <b>{{ showNumber((reportData.details) ? reportData.details.land.nacional.boarded.files.total_realized : 0.00, '.') }}</b>
                          / {{ showGoal(((reportData.details) ? (reportData.details.land.nacional.boarded.files.total_goal) : 0), '.', '-') }}
                        </div>
                        PROCESSOS<br/><span class="small"><b>NAC</b>IONAL</span>
                      </template>
                    </card>
                  </div>

                  <div class="row justify-content-md-center">
                    <!-- AIR FILES -->
                    <card classRoot="col-lg-3 col-md-6 col-xs-12" v-show="reportData.config.showAirFiles">
                      <template v-slot:title>
                        {{ (reportData.summary) ? showNumber(reportData.summary.air.current) : 0 }} / {{ (reportData.summary) ? showNumber(reportData.summary.air.goal) : 0 }}
                      </template>

                      <template>
                        <donnut-chart
                          elementId="chart-maritime-air-boarded"
                          :value="(reportData.summary) ? reportData.summary.air.current : 0.0"
                          :goal="(reportData.summary) ? (reportData.summary.air.goal * 1.0) : 0.0"
                          :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.air)) : 0.0"
                          width="150px"
                          height="150px"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
                          AÉREO
                        </donnut-chart>

                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-air-boarded"
                            :value="(reportData.summary) ? reportData.summary.air.goalPerMonth : []"
                            width="150px"
                            height="50px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                        </div>
                      </template>

                      <template v-slot:legend>
                        <div class="small mb-2">
                          <b>{{ showNumber((reportData.summary) ? reportData.summary.air.total_realized : 0.00, '.') }}</b>
                          / {{ showGoal(((reportData.summary) ? (reportData.summary.air.total_goal) : 0), '.', '-') }}
                        </div>
                        PROCESSOS<br/><span class="small"><b>IM</b>PO & <b>EX</b>PO</span>
                      </template>
                    </card>

                    <!-- AIR TONS -->
                    <card classRoot="col-lg-3 col-md-6 col-xs-12" v-show="!reportData.config.showAirFiles">
                      <template v-slot:title>
                        {{ (reportData.summary) ? showNumber(reportData.summary.air.tons.current) : 0 }} / {{ (reportData.summary) ? showNumber(reportData.summary.air.tons.goal) : 0 }}
                      </template>

                      <template>
                        <donnut-chart
                          elementId="chart-air-boarded-tons"
                          :value="(reportData.summary) ? reportData.summary.air.tons.current : 0.0"
                          :goal="(reportData.summary) ? (reportData.summary.air.tons.goal * 1.0) : 0.0"
                          :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.air.tons)) : 0.0"
                          width="150px"
                          height="150px"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
                          AÉREO
                        </donnut-chart>

                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-air-boarded-tons"
                            :value="(reportData.summary) ? reportData.summary.air.tons.goalPerMonth : []"
                            width="150px"
                            height="50px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                        </div>
                      </template>

                      <template v-slot:legend>
                        <div class="small mb-2">
                          <b>{{ showNumber((reportData.summary) ? reportData.summary.air.tons.total_realized : 0.00, '.') }}</b>
                          / {{ showGoal(((reportData.summary) ? (reportData.summary.air.tons.total_goal) : 0), '.', '-') }}
                        </div>
                        TONS<br/><span class="small"><b>IM</b>PO & <b>EX</b>PO</span>
                      </template>
                    </card>
                  </div>
                </div>
                <!-- AMOUNT FILES -->
                <card classRoot="col-lg-3 col-md-6 col-xs-12" v-show="!showNewChart">
                  <template v-slot:title>
                    R$ {{ (reportData.summary) ? showNumber(reportData.summary.amount.current) : '0,00' }} / R$ {{ (reportData.summary) ? showNumber(reportData.summary.amount.goal) : '0,00' }}
                  </template>

                  <template>
                    <donnut-chart
                      elementId="chart-maritime-amount-boarded"
                      :value="(reportData.summary) ? reportData.summary.amount.current : 0.0"
                      :goal="(reportData.summary) ? (reportData.summary.amount.goal * 1.0) : 0.0"
                      :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.amount)) : 0.0"
                      width="150px"
                      height="150px"
                      :topOffset="54"
                      :empty-color="'#fefefe'"
                    >
                      $
                    </donnut-chart>

                    <div class="text-center progress-chart">
                      <bar-chart
                        elementId="bar-chart-maritime-amount-boarded"
                        :value="(reportData.summary) ? reportData.summary.amount.goalPerMonth : []"
                        width="150px"
                        height="50px"
                        :formatData="barChartTransform"
                        :topOffset="54"
                        :empty-color="'#fefefe'"
                      >
                        x / Y
                      </bar-chart>
                    </div>
                  </template>

                  <template v-slot:legend>
                    <div class="small mb-2">
                    <b>R$ {{ showNumber((reportData.summary) ? reportData.summary.amount.total_realized : 0.00, '.') }}</b>
                    / R$ {{ showGoal(((reportData.summary) ? (reportData.summary.amount.total_goal) : 0), '.', '-') }}
                    </div>
                    RECEBIDOS<br/><span class="small"><b>IM</b>PO & <b>EX</b>PO</span>
                  </template>
                </card>
              </div>
              <hr/>
              <div class="row message-box">
                <div class="message col-sm-12 text-justify" v-html="reportData.message" />
              </div>

              <!-- Maritime IMPO -->
              <div class="row first detail-box" v-show="!showNewChart">
                <div class="col-md-2 d-none d-lg-block" >
                  <div style="position: relative; top: 279px; overflow: hidden; text-align: right">
                    <img width="162px"
                         :src="asset('static/images/report/fcl_label.png')" />
                  </div>
                </div>
                <div class="col-md-12 col-lg-8 indicator-box">
                  <div class="row">
                    <div class="col-md-12 d-block d-lg-none text-center">
                      <h5  class="box-title section-title mb-5"><b>IM</b>PO - Marítima</h5>
                    </div>
                    <div class="col-md-4 ">

                      <div class="card-title" v-if="reportData.config.showFCLFiles" >
                        <b>{{ (reportData.details) ? reportData.details.fcl.import.toBoard.teus.current : 0.00 }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.fcl.import.toBoard.teus.goal * 1.0) : 0), '.', '-') }}
                      </div>
                      <div class="card-title info"  v-else >
                        ABERTOS <br/>
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.fcl.import.toBoard.files.amount : 0, '.') }}</b> <br/>
                        <b>{{ (reportData.details) ? reportData.details.fcl.import.toBoard.teus.current : 0.00 }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.fcl.import.toBoard.teus.goal * 1.0) : 0), '.', '-') }} <br/>
                        <i class="fa fa-caret-down"></i><br/>
                      </div>

                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-impo-teus"
                          :value="(reportData.details) ?reportData.details.fcl.import.toBoard.teus.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.fcl.import.toBoard.teus.goal * 1.0) : 0.0"
                          :percentMark="(reportData.summary) ? (getPercentMark(reportData.details.fcl.import.toBoard.teus)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">TEUS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-maritime-impo-teus"
                            :value="(reportData.summary) ? reportData.details.fcl.import.toBoard.teus.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.fcl.import.toBoard.teus.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.fcl.import.toBoard.teus.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card-title center info" v-if="reportData.config.showFCLFiles" >
                        <i class="fa fa-caret-left"></i>&nbsp;&nbsp;
                        ABERTOS
                        &nbsp;&nbsp;<i class="fa fa-caret-right"></i>
                      </div>
                      <div class="card-legend info" v-if="reportData.config.showFCLFiles">
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.fcl.import.toBoard.files.amount : 0, '.') }}</b>
                      </div>
                    </div>
                    <div class="col-md-4" v-show="reportData.config.showFCLFiles">
                      <div class="card-title">
                        <b>{{ (reportData.details) ? reportData.details.fcl.import.toBoard.files.current : 0 }}</b>/{{ (reportData.details) ? showGoal(reportData.details.fcl.import.toBoard.files.goal * 1.0, '.', '-') : '0,00' }}
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-impo-files"
                          :value="(reportData.details) ?reportData.details.fcl.import.toBoard.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.fcl.import.toBoard.files.goal  * 1.0): 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.fcl.import.toBoard.files)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">

                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-maritime-fcl-import-toBoard-files"
                            :value="(reportData.summary) ? reportData.details.fcl.import.toBoard.files.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.fcl.import.toBoard.files.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.fcl.import.toBoard.files.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card-title" v-if="reportData.config.showFCLFiles" >
                        <b>{{ showNumber((reportData.details) ? reportData.details.fcl.import.boarded.teus.current : 0) }}</b>/{{ (reportData.details) ? showGoal(reportData.details.fcl.import.boarded.teus.goal * 1.0, '.', '-') : '-' }}
                      </div>
                      <div class="card-title info"  v-else>
                        EMBARCADOS <br/>
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.fcl.import.boarded.files.amount : 0, '.' )}}</b> <br/>
                        <b>{{ showNumber((reportData.details) ? reportData.details.fcl.import.boarded.teus.current : 0) }}</b>/{{ (reportData.details) ? showGoal(reportData.details.fcl.import.boarded.teus.goal * 1.0, '.', '-') : '-' }} <br/>
                        <i class="fa fa-caret-down"></i><br/>
                      </div>

                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-board-impo-teus"
                          :value="(reportData.details) ? reportData.details.fcl.import.boarded.teus.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.fcl.import.boarded.teus.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.fcl.import.boarded.teus)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">TEUS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart">
                          <bar-chart
                            elementId="bar-chart-maritime-fcl-import-boarded-teus"
                            :value="(reportData.summary) ? reportData.details.fcl.import.boarded.teus.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.fcl.import.boarded.teus.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.fcl.import.boarded.teus.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-4" v-if="reportData.config.showFCLFiles">
                      <div class="card-title center info">
                        <i class="fa fa-caret-left"></i>&nbsp;&nbsp;
                        EMBARCADOS
                        &nbsp;&nbsp;<i class="fa fa-caret-right"></i>
                      </div>

                      <div class="card-legend info">
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.fcl.import.boarded.files.amount : 0, '.' )}}</b>
                      </div>
                    </div>

                    <div class="col-md-4" v-show="reportData.config.showFCLFiles">
                      <div class="card-title">
                        <b>{{ showNumber( (reportData.details) ? reportData.details.fcl.import.boarded.files.current : 0 )}}</b>/{{ (reportData.details) ? showGoal(reportData.details.fcl.import.boarded.files.goal * 1.0, '.','-') : '-' }}
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-board-impo-file"
                          :value="(reportData.details) ? reportData.details.fcl.import.boarded.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.fcl.import.boarded.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.fcl.import.boarded.files)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-maritime-fcl-import-boarded-files"
                            :value="(reportData.summary) ? reportData.details.fcl.import.boarded.files.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.fcl.import.boarded.files.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.fcl.import.boarded.files.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-md-2 d-none d-lg-block">
                  <p><b>IM</b>PO</p>
                </div>
              </div>

              <!-- Maritime EXPO -->
              <div class="row middle detail-box" v-show="!showNewChart">
                <div class="col-md-2 d-none d-lg-block">
                  &nbsp;
                </div>
                <div class="col-md-12 col-lg-8 indicator-box">
                  <div class="row">
                    <div class="col-md-12 d-block d-lg-none text-center">
                      <h5  class="box-title section-title mb-5"><b>EX</b>PO - Marítima</h5>
                    </div>
                    <div class="col-md-4">

                      <div class="card-title" v-if="reportData.config.showFCLFiles" >
                        <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.toBoard.teus.current : 0) }}</b>/{{ showGoal((reportData.details) ? (reportData.details.fcl.export.toBoard.teus.goal * 1.0) : 0, '.', '-') }}
                      </div>
                      <div class="card-title info"  v-else >
                        ABERTOS <br/>
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.fcl.export.toBoard.files.amount : 0, '.' ) }}</b> <br/>
                        <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.toBoard.teus.current : 0) }}</b>/{{ showGoal((reportData.details) ? (reportData.details.fcl.export.toBoard.teus.goal * 1.0) : 0, '.', '-') }} <br/>
                        <i class="fa fa-caret-down"></i><br/>
                      </div>

                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-expo-teus"
                          :value="(reportData.details) ?reportData.details.fcl.export.toBoard.teus.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.fcl.export.toBoard.teus.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.fcl.export.toBoard.teus)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">TEUS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart">
                          <bar-chart
                            elementId="bar-chart-maritime-expo-teus"
                            :value="(reportData.summary) ? reportData.details.fcl.export.toBoard.teus.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.toBoard.teus.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.fcl.export.toBoard.teus.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-4">
                      <div class="card-title center info" v-if="reportData.config.showFCLFiles">
                        <i class="fa fa-caret-left"></i>
                        ABERTOS
                        &nbsp;&nbsp;<i class="fa fa-caret-right"></i>
                      </div>

                      <div class="card-legend info" v-if="reportData.config.showFCLFiles">
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.fcl.export.toBoard.files.amount : 0, '.' ) }}</b>
                      </div>
                    </div>
                    <div class="col-md-4" v-show="reportData.config.showFCLFiles" >
                      <div class="card-title">
                        <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.toBoard.files.current : 0 )}}</b>/{{ showGoal((reportData.details) ? (reportData.details.fcl.export.toBoard.files.goal * 1.0) : 0, '.', '-')}}
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-expo-files"
                          :value="(reportData.details) ?reportData.details.fcl.export.toBoard.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.fcl.export.toBoard.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.fcl.export.toBoard.files)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart">
                          <bar-chart
                            elementId="bar-chart-maritime-expo-files"
                            :value="(reportData.summary) ? reportData.details.fcl.export.toBoard.files.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.toBoard.files.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.fcl.export.toBoard.files.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>

                      </div>

                    </div>

                    <div class="col-md-4">
                      <div class="card-title" v-if="reportData.config.showFCLFiles" >
                        <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.boarded.teus.current : 0 ) }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.fcl.export.boarded.teus.goal * 1.0) : 0), '.', '-') }}
                      </div>
                      <div class="card-title info"  v-else>
                        EMBARCADOS <br/>
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.fcl.export.boarded.files.amount : 0, '.' )}}</b> <br/>
                        <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.boarded.teus.current : 0 ) }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.fcl.export.boarded.teus.goal * 1.0) : 0), '.', '-') }} <br/>
                        <i class="fa fa-caret-down"></i><br/>
                      </div>

                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-board-expo-teus"
                          :value="(reportData.details) ? reportData.details.fcl.export.boarded.teus.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.fcl.export.boarded.teus.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.fcl.export.boarded.teus)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">TEUS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart">
                          <bar-chart
                            elementId="bar-chart-maritime-boarded-expo-teus"
                            :value="(reportData.summary) ? reportData.details.fcl.export.boarded.teus.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.boarded.teus.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.fcl.export.boarded.teus.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-4">
                      <div class="card-title center info" v-if="reportData.config.showFCLFiles">
                        <i class="fa fa-caret-left"></i>&nbsp;&nbsp;
                        EMBARCADOS
                        &nbsp;&nbsp;<i class="fa fa-caret-right"></i>
                      </div>

                      <div class="card-legend info" v-if="reportData.config.showFCLFiles">
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.fcl.export.boarded.files.amount : 0, '.')}}</b>
                      </div>
                    </div>

                    <div class="col-md-4"  v-show="reportData.config.showFCLFiles">
                      <div class="card-title">
                        <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.boarded.files.current : 0) }}</b>/{{ showGoal((reportData.details) ? (reportData.details.fcl.export.boarded.files.goal * 1.0) : 0, '.', '-') }}
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-board-expo-files"
                          :value="(reportData.details) ? reportData.details.fcl.export.boarded.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.fcl.export.boarded.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.fcl.export.boarded.files)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-maritime-boarded-expo-files"
                            :value="(reportData.summary) ? reportData.details.fcl.export.boarded.files.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.fcl.export.boarded.files.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.fcl.export.boarded.files.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 d-none d-lg-block">
                  <p style="top: 135px">
                    <b>EX</b>PO
                  </p>
                </div>
              </div>

              <!-- Maritime LCL -->
              <div class="row last detail-box" v-show="!showNewChart">
                <div class="col-md-2 d-none d-lg-block">
                  <div style="position: relative; top: 47px; overflow: hidden; text-align: right">
                    <img width="162px" :src="asset('static/images/report/lcl_label.png')"/>
                  </div>
                </div>
                <div class="col-md-12 col-lg-8 indicator-box">
                  <div class="row">
                    <div class="col-md-12 d-block d-lg-none text-center">
                      <h5  class="box-title section-title mb-5"><b>EX</b>PO & <b>IM</b>PO - LCL</h5>
                    </div>
                    <div class="col-md-4">
                      <div class="card-title info">
                        ABERTOS <br/>
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.lcl.toBoard.files.amount : 0 , '.')}}</b> <br/>
                        <b>{{ showNumber(((reportData.details) ? reportData.details.lcl.toBoard.files.current : 0),'.') }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.lcl.toBoard.files.goal * 1.0) : 0), '.', '-') }} <br/>
                        <i class="fa fa-caret-down"></i><br/>
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-lcl-files"
                          :value="(reportData.details) ? reportData.details.lcl.toBoard.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.lcl.toBoard.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.lcl.toBoard.files)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart">
                          <bar-chart
                            elementId="bar-chart-maritime-lcl-files"
                            :value="(reportData.summary) ? reportData.details.lcl.toBoard.files.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.lcl.toBoard.files.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.lcl.toBoard.files.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card-title">
                        &nbsp;
                      </div>

                      <div class="card-legend">
                        &nbsp;
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="card-title info">
                        EMBARCADOS<br/>
                        <b>R$ {{ showNumber((reportData.details) ? reportData.details.lcl.boarded.files.amount : 0, '.')}}</b><br/>
                        <b>{{ showNumber(((reportData.details) ? reportData.details.lcl.boarded.files.current : 0), '.') }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.lcl.boarded.files.goal * 1.0):0), '.', '-') }}
                        <br/><i class="fa fa-caret-down"></i>
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-maritime-lcl-boarded-files"
                          :value="(reportData.details) ? reportData.details.lcl.boarded.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.lcl.boarded.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.lcl.boarded.files)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart">
                          <bar-chart
                            elementId="bar-chart-maritime-lcl-boarded-files"
                            :value="(reportData.summary) ? reportData.details.lcl.boarded.files.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.lcl.boarded.files.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.lcl.boarded.files.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 d-none d-lg-block">
                  <p style="top: 135px">
                    <b>EX</b>PO <br/>
                    & <b>IM</b>PO
                  </p>
                </div>
              </div>

              <!-- Aéreo-->
              <div class="row simple  detail-box" v-show="!showNewChart">
                <div class="col-md-2 d-none d-lg-block">
                  <div style="position: relative; top: 165px; overflow: hidden; text-align: right">
                    <img width="162px"
                         :src="asset('static/images/report/air_label.png')"/>
                  </div>
                </div>
                <div class="col-md-12 col-lg-8 indicator-box">
                  <div class="row">
                    <div class="col-md-12 d-block d-lg-none text-center">
                      <h5  class="box-title section-title mb-5"><b>EX</b>PO & <b>IM</b>PO - Aéreo</h5>
                    </div>
                    <div class="col-md-4">
                      <div class="card-title" v-if="reportData.config.showAirFiles" >
                        <b>{{ showNumber(((reportData.details) ? reportData.details.air.toBoard.tons.current : 0) )}}</b>/{{ showGoal(((reportData.details) ? (reportData.details.air.toBoard.tons.goal * 1.0): 0), '.', '-') }}
                      </div>
                      <div class="card-title info"  v-else >
                        ABERTOS <br/>
                        <b>R$ {{ showNumber(((reportData.details) ? reportData.details.air.toBoard.files.amount : null), '.') }}</b> <br/>
                        <b>{{ showNumber(((reportData.details) ? reportData.details.air.toBoard.tons.current : 0) )}}</b>/{{ showGoal(((reportData.details) ? (reportData.details.air.toBoard.tons.goal * 1.0): 0), '.', '-') }} <br/>
                        <i class="fa fa-caret-down"></i><br/>
                      </div>

                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-aereo-tons"
                          :value="(reportData.details) ? reportData.details.air.toBoard.tons.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.air.toBoard.tons.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.air.toBoard.tons)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">TONS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart">
                          <bar-chart
                            elementId="bar-chart-aereo-tons"
                            :value="(reportData.details) ? reportData.details.air.toBoard.tons.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.air.toBoard.tons.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.air.toBoard.tons.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-4">
                      <div class="card-title center info" v-if="reportData.config.showAirFiles">
                        <i class="fa fa-caret-left"></i>&nbsp;&nbsp;
                        ABERTOS
                        &nbsp;&nbsp;<i class="fa fa-caret-right"></i>
                      </div>

                      <div class="card-legend info" v-if="reportData.config.showAirFiles">
                        <b>R$ {{ showNumber(((reportData.details) ? reportData.details.air.toBoard.files.amount : null), '.') }}</b>
                      </div>

                    </div>

                    <div class="col-md-4" v-show="reportData.config.showAirFiles">
                      <div class="card-title">
                        <b>{{ showNumber(((reportData.details) ? reportData.details.air.toBoard.files.current: 0)) }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.air.toBoard.files.goal * 1.0) : 0), '.', '-') }}
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-aereo-files"
                          :value="(reportData.details) ? reportData.details.air.toBoard.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.air.toBoard.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.air.toBoard.files)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-aereo-files"
                            :value="(reportData.details) ? reportData.details.air.toBoard.files.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.air.toBoard.files.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.air.toBoard.files.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="card-title" v-if="reportData.config.showAirFiles" >
                        <b>{{ showNumber(((reportData.details) ? reportData.details.air.boarded.tons.current: 0)) }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.air.boarded.tons.goal * 1.0): 0), '.', '-') }}
                      </div>
                      <div class="card-title info"  v-else>
                        EMBARCADOS <br/>
                        <b>R$ {{ showNumber(((reportData.details) ? reportData.details.air.boarded.files.amount: 0), '.') }}</b> <br/>
                        <b>{{ showNumber(((reportData.details) ? reportData.details.air.boarded.tons.current: 0)) }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.air.boarded.tons.goal * 1.0): 0), '.', '-') }} <br/>
                        <i class="fa fa-caret-down"></i><br/>
                      </div>

                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-aereo-board-tons"
                          :value="(reportData.details) ? reportData.details.air.boarded.tons.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.air.boarded.tons.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.air.boarded.tons)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">TONS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart">
                          <bar-chart
                            elementId="bar-chart-aereo-board-tons"
                            :value="(reportData.details) ? reportData.details.air.boarded.tons.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.air.boarded.tons.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.air.boarded.tons.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-4">
                      <div class="card-title center info" v-if="reportData.config.showAirFiles">
                        <i class="fa fa-caret-left"></i>&nbsp;&nbsp;
                        EMBARCADOS
                        &nbsp;&nbsp;<i class="fa fa-caret-right"></i>
                      </div>

                      <div class="card-legend info" v-if="reportData.config.showAirFiles">
                        <b>R$ {{ showNumber(((reportData.details) ? reportData.details.air.boarded.files.amount: 0), '.') }}</b>
                      </div>
                    </div>
                    <div class="col-md-4" v-show="reportData.config.showAirFiles" >
                      <div class="card-title">
                        <b>{{ showNumber(((reportData.details) ? reportData.details.air.boarded.files.current : 0)) }}</b>/{{ showGoal(((reportData.details) ? (reportData.details.air.boarded.files.goal * 1.0) : 0), '.', '-') }}
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-air-board-files"
                          :value="(reportData.details) ? reportData.details.air.boarded.files.current : 0.0"
                          :goal="(reportData.details) ? (reportData.details.air.boarded.files.goal * 1.0) : 0.0"
                          :percentMark="(reportData.details) ? (getPercentMark(reportData.details.air.boarded.files)) : 0.0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="text-center progress-chart" >
                          <bar-chart
                            elementId="bar-chart-aereo-board-files"
                            :value="(reportData.details) ? reportData.details.air.boarded.files.goalPerMonth : []"
                            width="120px"
                            height="40px"
                            :formatData="barChartTransform"
                            :topOffset="54"
                            :empty-color="'#fefefe'"
                          >
                            x / Y
                          </bar-chart>
                          <div class="small mb-2">
                            <b>{{ showNumber((reportData.details) ? reportData.details.air.boarded.files.total_realized : 0.00, '.') }}</b>
                            / {{ showGoal(((reportData.details) ? (reportData.details.air.boarded.files.total_goal) : 0), '.', '-') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 d-none d-lg-block">
                  <p style="top: 172px">
                    <b>EX</b>PO <br/>
                    & <b>IM</b>PO
                  </p>
                </div>
              </div>

              <!-- Seguro-->
              <div class="row simple insurance  detail-box">
                <div class="col-md-2 d-none d-lg-block">
                  <div style="position: relative; top: 189px; overflow: hidden; text-align: right">
                    <img width="162px"
                         :src="asset('static/images/report/insurance_label.png')"/>
                  </div>
                </div>
                <div class="col-md-12 col-lg-8 indicator-box">
                  <div class="row">
                    <div class="col-md-12 d-block d-lg-none text-center">
                      <h5  class="box-title section-title mb-5"><b>EX</b>PO & <b>IM</b>PO - Seguro</h5>
                    </div>
                    <div class="col-md-12">
                      <div class="card-title">
                        <b>{{ (reportData.details) ? reportData.details.insurance.files.current : 0 }}</b>
                        /
                        {{ showNumber((reportData.details) ? getPercentualInsuranceGoal(totalFiles, reportData.details.insurance.files.goal ) : 0 , '.', '-') }}
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-insurance-files"
                          :value="(reportData.details) ? reportData.details.insurance.files.current : 0.0"
                          :goal="(reportData.details) ? getPercentualInsuranceGoal(totalFiles, reportData.details.insurance.files.goal ) : 0"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="small">META DE SEGUROS</div>
                      </div>

                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card-title">
                        <b>{{ (reportData.details) ? reportData.details.insurance.files.current  : 0}}</b>/{{ totalFiles }}
                      </div>
                      <div class="card-body">
                        <donnut-chart
                          elementId="chart-insurance-total-files"
                          :value="(reportData.details) ? reportData.details.insurance.files.current : 0.0"
                          :goal="totalFiles"
                          width="120px"
                          height="120px"
                          :topOffset="34"
                          :percentMark="(reportData.details) ? (reportData.details.insurance.files.goal ) : 0"
                        >
                          <div class="medium">PROCESSOS</div>
                        </donnut-chart>
                      </div>
                      <div class="card-legend">
                        <div class="small">% DE PROCESSOS SEGURADOS</div>

                      </div>

                    </div>
                  </div>

                </div>
                <div class="col-md-2 d-none d-lg-block">
                  <p style="top: 189px">
                    <b>EX</b>PO <br/>
                    & <b>IM</b>PO
                  </p>
                </div>
              </div>

              <!-- Atividades-->
              <div class="row simple detail-box" v-show="showNewChart">
                <div class="col-md-1 d-none d-lg-block">
                  <div style="position: relative; top: 189px; overflow: hidden; text-align: right">

                  </div>
                </div>
                <div class="mb-5 col-md-12 col-lg-10 indicator-box content-box">
                  <div class="row">
                    <div class="col-md-12 d-block d-lg-none text-center">
                    </div>
                    <div class="col-md-12">
                      <div class="card-title">
                        Atividades
                      </div>
                      <div class="card-body">
                        <div class="table-responsive">
                        <table class="table table-striped table-hover">
                          <thead>
                          <tr>
                            <th class="align-middle">Atividade</th>
                            <th v-for="tableHeader in tableHeaders()" v-bind:key="tableHeader.period"  class="text-center">{{ tableHeader.title }}</th>
                          </tr>

                          </thead>
                          <tbody>
                          <tr>
                            <td>Apresentação</td>
                            <td v-for="tableHeader in tableHeaders()" v-bind:key="tableHeader.period"  class="text-center">
                              {{ (reportData.summary) ? getValueByPeriod(reportData.summary.presentation, 'realized', filters, tableHeader.period) : 0.0 }} / {{ (reportData.summary) ? getValueByPeriod(reportData.summary.presentation, 'goal', filters, tableHeader.period) : 0.0 }}
                            </td>
                          </tr>
                          <tr>
                            <td>Visitas</td>
                            <td v-for="tableHeader in tableHeaders()" v-bind:key="tableHeader.period"  class="text-center">
                              {{ (reportData.summary) ? getValueByPeriod(reportData.summary.visit, 'realized', filters, tableHeader.period) : 0.0 }} / {{ (reportData.summary) ? getValueByPeriod(reportData.summary.visit, 'goal', filters, tableHeader.period) : 0.0 }}
                            </td>
                          </tr>
                          <tr>
                            <td>Contatos Tel.</td>
                            <td v-for="tableHeader in tableHeaders()" v-bind:key="tableHeader.period"  class="text-center">
                              {{ (reportData.summary) ? getValueByPeriod(reportData.summary.phoneCall, 'realized', filters, tableHeader.period) : 0.0 }} / {{ (reportData.summary) ? getValueByPeriod(reportData.summary.phoneCall, 'goal', filters, tableHeader.period) : 0.0 }}
                            </td>
                          </tr>
                          <tr>
                            <td>Contatos SKP/Whats</td>
                            <td v-for="tableHeader in tableHeaders()" v-bind:key="tableHeader.period"  class="text-center">
                              {{ (reportData.summary) ? getValueByPeriod(reportData.summary.skpWhats, 'realized', filters, tableHeader.period) : 0.0 }} / {{ (reportData.summary) ? getValueByPeriod(reportData.summary.skpWhats, 'goal', filters, tableHeader.period) : 0.0 }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-1 d-none d-lg-block">
                </div>
              </div>

              <div class="activities" v-show="!showNewChart" >
                <h3 class="message">ATIVIDADES</h3>
                <hr/>
                <div class="row justify-content-md-center">
                  <!-- Visitas -->
                  <card classRoot="col-md-6 col-lg-4 activity" v-show="reportData.config.showVisitas">

                  <template v-slot:title>
                    <div class="icon visits"></div>
                    VISITAS
                  </template>

                  <template>
                    <donnut-chart
                      elementId="chart-visits"
                      :value="(reportData.summary) ? reportData.summary.visit.current : 0.0"
                      :goal="(reportData.summary) ? (reportData.summary.visit.goal * 1.0) : 0.0"
                      :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.visit)) : 0.0"
                      width="115px"
                      height="115px"
                      :topOffset="34"
                      :empty-color="'#fefefe'"
                    >
                      <span style="font-weight: bold;font-size: 0.75rem;">
                      {{ showInteger((reportData.summary) ? reportData.summary.visit.current : 0) }}/{{ showIntegerGoal((reportData.summary) ? (reportData.summary.visit.goal * 1.0) : 0,'.','-') }}
                      </span>
                    </donnut-chart>

                    <div class="text-center progress-chart">
                      <bar-chart
                        elementId="bar-chart-visits"
                        :value="(reportData.summary) ? reportData.summary.visit.goalPerMonth : []"
                        width="115px"
                        height="35px"
                        :formatData="barChartTransform"
                        :topOffset="54"
                        :empty-color="'#fefefe'"
                      >
                        x / Y
                      </bar-chart>
                      <div class="small mb-2">
                        <b>{{ showInteger((reportData.summary) ? reportData.summary.visit.total_realized : 0.00, '.') }}</b>
                        / {{ showIntegerGoal(((reportData.summary) ? (reportData.summary.visit.total_goal) : 0), '.', '-') }}
                      </div>
                    </div>
                  </template>
                </card>

                  <!-- E-mails -->
                  <card classRoot="col-md-6 col-lg-4 activity" v-show="reportData.config.showEmails">

                    <template v-slot:title>
                      <div class="icon email"></div>
                      E-MAILS
                    </template>

                    <template>
                      <donnut-chart
                        elementId="chart-email"
                        :value="(reportData.summary) ? reportData.summary.email.current : 0.0"
                        :goal="(reportData.summary) ? (reportData.summary.email.goal * 1.0) : 0.0"
                        :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.email)) : 0.0"
                        width="115px"
                        height="115px"
                        :topOffset="34"
                        :empty-color="'#fefefe'"
                      >
                      <span style="font-weight: bold;font-size: 0.75rem;">
                      {{ showInteger((reportData.summary) ? reportData.summary.email.current : 0) }}/{{ showIntegerGoal((reportData.summary) ? (reportData.summary.email.goal * 1.0) : 0,'.','-') }}
                      </span>
                      </donnut-chart>

                      <div class="text-center progress-chart">
                        <bar-chart
                          elementId="bar-chart-email"
                          :value="(reportData.summary) ? reportData.summary.email.goalPerMonth : []"
                          width="115px"
                          height="35px"
                          :formatData="barChartTransform"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
                          x / Y
                        </bar-chart>
                        <div class="small mb-2">
                          <b>{{ showInteger((reportData.summary) ? reportData.summary.email.total_realized : 0.00, '.') }}</b>
                          / {{ showIntegerGoal(((reportData.summary) ? (reportData.summary.email.total_goal) : 0), '.', '-') }}
                        </div>
                      </div>
                    </template>
                  </card>

                  <!-- Apresentações-->
                <card classRoot="col-md-6 col-lg-4">
                  <template v-slot:title>
                    <div class="icon presentation"></div>
                    APRESENTAÇÕES
                  </template>

                  <template>
                    <donnut-chart
                      elementId="chart-presentation"
                      :value="(reportData.summary) ? reportData.summary.presentation.current : 0.0"
                      :goal="(reportData.summary) ? (reportData.summary.presentation.goal * 1.0) : 0.0"
                      :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.presentation)) : 0.0"
                      width="115px"
                      height="115px"
                      :topOffset="34"
                      :empty-color="'#fefefe'"
                    >
                      <span style="font-weight: bold;font-size: 0.75rem;">
                      {{ (reportData.summary) ? reportData.summary.presentation.current : 0 }}/{{ showIntegerGoal((reportData.summary) ? (reportData.summary.presentation.goal * 1.0) : 0, '.','-') }}
                      </span>
                    </donnut-chart>

                    <div class="text-center progress-chart" >
                      <bar-chart
                        elementId="bar-chart-presentation"
                        :value="(reportData.summary) ? reportData.summary.presentation.goalPerMonth : []"
                        width="115px"
                        height="35px"
                        :formatData="barChartTransform"
                        :topOffset="54"
                        :empty-color="'#fefefe'"
                      >
                        x / Y
                      </bar-chart>
                      <div class="small mb-2">
                        <b>{{ showInteger((reportData.summary) ? reportData.summary.presentation.total_realized : 0.00, '.') }}</b>
                        / {{ showIntegerGoal(((reportData.summary) ? (reportData.summary.presentation.total_goal) : 0), '.', '-') }}
                      </div>
                    </div>
                  </template>
                </card>

                <!-- Contatos Tel -->
                <card classRoot="col-md-6 col-lg-4">
                  <template v-slot:title>
                    <div class="icon callPhones"></div>
                    CONTATOS TEL
                  </template>

                  <template>
                    <donnut-chart
                      elementId="chart-phonecall"
                      :value="(reportData.summary) ? reportData.summary.phoneCall.current : 0.0"
                      :goal="(reportData.summary) ? reportData.summary.phoneCall.goal * 1.0 : 0.0"
                      :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.phoneCall)) : 0.0"
                      width="115px"
                      height="115px"
                      :topOffset="34"
                      :empty-color="'#fefefe'"
                    >
                      <span style="font-weight: bold;font-size: 0.75rem;">
                      {{ (reportData.summary) ? reportData.summary.phoneCall.current : 0 }}/{{ showIntegerGoal((reportData.summary) ? reportData.summary.phoneCall.goal * 1.0 : 0,'.','-') }}
                      </span>
                    </donnut-chart>

                    <div class="text-center progress-chart" >
                      <bar-chart
                        elementId="bar-chart-phoneCall"
                        :value="(reportData.summary) ? reportData.summary.phoneCall.goalPerMonth : []"
                        width="115px"
                        height="35px"
                        :formatData="barChartTransform"
                        :topOffset="54"
                        :empty-color="'#fefefe'"
                      >
                        x / Y
                      </bar-chart>
                      <div class="small mb-2">
                        <b>{{ showInteger((reportData.summary) ? reportData.summary.phoneCall.total_realized : 0.00, '.') }}</b>
                        / {{ showIntegerGoal(((reportData.summary) ? (reportData.summary.phoneCall.total_goal) : 0), '.', '-') }}
                      </div>
                    </div>
                  </template>
                </card>

                <!-- Contatos Skp / Whats -->
                  <card classRoot="col-md-6 col-lg-4">
                    <template v-slot:title>
                      <div class="icon callPhones"></div>
                      CONTATOS SKP/WHATS
                    </template>

                    <template>
                      <donnut-chart
                        elementId="chart-skpWhats"
                        :value="(reportData.summary) ? reportData.summary.skpWhats.current : 0.0"
                        :goal="(reportData.summary) ? reportData.summary.skpWhats.goal * 1.0 : 0.0"
                        :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.skpWhats)) : 0.0"
                        width="115px"
                        height="115px"
                        :topOffset="34"
                        :empty-color="'#fefefe'"
                      >
                      <span style="font-weight: bold;font-size: 0.75rem;">
                      {{ (reportData.summary) ? reportData.summary.skpWhats.current : 0 }}/{{ showIntegerGoal((reportData.summary) ? reportData.summary.skpWhats.goal * 1.0 : 0,'.','-') }}
                      </span>
                      </donnut-chart>

                      <div class="text-center progress-chart" >
                        <bar-chart
                          elementId="bar-chart-skpWhats"
                          :value="(reportData.summary) ? reportData.summary.skpWhats.goalPerMonth : []"
                          width="115px"
                          height="35px"
                          :formatData="barChartTransform"
                          :topOffset="54"
                          :empty-color="'#fefefe'"
                        >
                          x / Y
                        </bar-chart>
                        <div class="small mb-2">
                          <b>{{ showInteger((reportData.summary) ? reportData.summary.skpWhats.total_realized : 0.00, '.') }}</b>
                          / {{ showIntegerGoal(((reportData.summary) ? (reportData.summary.skpWhats.total_goal) : 0), '.', '-') }}
                        </div>
                      </div>
                    </template>
                  </card>

                <!-- Cotações -->
                <card classRoot="col-md-6 col-lg-4"  v-show="reportData.config.showCotacoes">
                  <template v-slot:title>
                    <div class="icon price"></div>
                    COTAÇÕES
                  </template>

                  <template>
                    <donnut-chart
                      elementId="chart-price"
                      :value="(reportData.summary) ? reportData.summary.price.current : 0.0"
                      :goal="(reportData.summary) ? reportData.summary.price.goal * 1.0 : 0.0"
                      :percentMark="(reportData.summary) ? (getPercentMark(reportData.summary.price)) : 0.0"
                      width="115px"
                      height="115px"
                      :topOffset="34"
                      :empty-color="'#fefefe'"
                    >
                      <span style="font-weight: bold;font-size: 0.75rem;">
                      {{ (reportData.summary) ? reportData.summary.price.current : 0 }}/{{ showIntegerGoal((reportData.summary) ? reportData.summary.price.goal * 1.0 : 0,'','-') }}
                      </span>
                    </donnut-chart>

                    <div class="text-center progress-chart" >
                      <bar-chart
                        elementId="bar-chart-price"
                        :value="(reportData.summary) ? reportData.summary.price.goalPerMonth : []"
                        width="115px"
                        height="35px"
                        :formatData="barChartTransform"
                        :topOffset="54"
                        :empty-color="'#fefefe'"
                      >
                        x / Y
                      </bar-chart>
                      <div class="small mb-2">
                        <b>{{ showInteger((reportData.summary) ? reportData.summary.price.total_realized : 0.00, '.') }}</b>
                        / {{ showIntegerGoal(((reportData.summary) ? (reportData.summary.price.total_goal) : 0), '.', '-') }}
                      </div>
                    </div>
                  </template>
                </card>
                </div>
              </div>
            </div>
            <!-- End gray-box -->
            <div class="dark-gray-box">
              <div class="row justify-content-sm-center">
                <div class="label air ">TRANSPORTE AÉREO</div>
                <div class="label ship">TRANSPORTE MARÍTIMO</div>
                <div class="label insurance">SEGURO</div>
                <div class="label fcl">FCL</div>
                <div class="label lcl">LCL</div>
              </div>
              <div class="row justify-content-sm-center mt-3 mb-2">
                <div class="display-1 label green">META ALCANÇADA</div>
                <div class="label yellow">51% ate 99,99%</div>
                <div class="label red">&lt; 50% </div>
              </div>
            </div>
            <!-- End dark-gray-box -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import Card from '@/components/Card'
import DonnutChart from '@/components/Chart/DonnutChart'
import BarChart from '@/components/Chart/BarChart'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import HorizontalGaugeChart from '@/components/Chart/amcharts4/HorizontalGaugeChart'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'
import ReportService from '@/services/ReportService'
// import SolidDonutChart from '@/components/Chart/amcharts4/SolidDonutChart'

export default {
  name: 'SellerQuarter',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.title') + ' ' + this.$i18n.t('report.seller.title') + ' - %s'
    }
  },
  data () {
    return {
      valor: 10.0,
      customerList: [],
      sellerList: [],
      tempPeriod: 'monthly',
      seller: {},
      reportData: {
        config: {
          showCotacoes: false,
          showVisitas: true,
          showEmails: true,
          showFCLFiles: false,
          showLCLFiles: false,
          showAirFiles: false
        }
      },
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        employee: null,
        year: null,
        month: null
      },
      firstFilter: true,
      filter: {}
    }
  },
  components: {
    // SolidDonutChart,
    BarChart,
    FilterSideBar,
    Loading,
    FilterContainer,
    DonnutChart,
    Card,
    HorizontalGaugeChart
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    if (this.user) {
      this.getReportSellerData()
    }
    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    tableHeaders () {
      let headers = [
        {
          title: 'Q1',
          period: 1
        },
        {
          title: 'Q2',
          period: 2
        },
        {
          title: 'Q3',
          period: 3
        },
        {
          title: 'Q4',
          period: 4
        }
      ]

      if (this.filters.filPeriodo === 'monthly') {
        headers = []
        for (let i = 1; i <= 12; i++) {
          headers.push(
            {
              title: this.$i18n.t('form.goal.idFrequency.monthly.' + i),
              period: i
            }
          )
        }
      }

      return headers
    },
    getValueByPeriod (indicator, attribute, filters, period) {
      let type = filters.filPeriodo
      let value = 0

      if (type === 'monthly') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]

          if (period === item.month) {
            value = item[attribute]
            break
          }
        }
      } else if (type === 'quarter') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]
          let quarter = parseInt(Math.ceil((item.month) / 3))
          if (period === quarter) {
            value += item[attribute]
          }
        }
      } else if (type === 'yearly') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]
          value += item[attribute]
        }
      }

      return this.numberFormat(value, 0, ',', '.')
    },
    sendNow () {
      let _this = this
      _this.buildFilters()

      _this.isLoading = true

      ReportService.getSellerQuarterSendMail(this.filter).then(res => {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      }).finally(function () {
        _this.isLoading = false
      })
    },
    getActiveFilterList () {
      let activeFilterList = {
        period: null,
        employee: null,
        year: null
      }

      if (this.filters.filPeriodo === 'quarter') {
        activeFilterList.quarter = null
      } else if (this.filters.filPeriodo === 'monthly') {
        activeFilterList.month = null
      }

      return activeFilterList
    },
    getTitle () {
      let title = ''

      if (this.filters.filPeriodo === 'monthly') {
        title = 'RESULTADO MENSAL'
      } else if (this.filters.filPeriodo === 'quarter') {
        title = 'RESULTADO TRIMESTRAL'
      } else if (this.filters.filPeriodo === 'yearly') {
        title = 'RESULTADO ANUAL'
      }

      return title
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getPercentMark (value) {
      let goalOnPeriod = 1
      let percentMark = 100

      if (value.goal > 0) {
        goalOnPeriod = value.goal * 1.0
      }
      percentMark = (value.goal * 100 / goalOnPeriod)

      return percentMark
    },
    getQuarter (date) {
      let quarter = parseInt(Math.ceil((date.getMonth() + 1) / 3))

      return quarter
    },
    getPercentualInsuranceGoal (totalFiles, percent) {
      let totalPercent = 0

      if (totalFiles) {
        totalPercent = totalFiles * percent / 100
      }

      return totalPercent
    },
    buildFilters () {
      if (['monthly', 'quarter', 'yearly'].indexOf(this.filters.filPeriodo) === -1) {
        this.filters.filPeriodo = 'monthly'
      }

      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      let today = new Date()
      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Semestre: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */
      if (!this.filters.filYear) {
        this.filters.filYear = today.getFullYear()
      }

      if ((!this.filters.filQuarter)) {
        this.filters.filQuarter = this.getQuarter(today)
      }

      if (!this.filters.filMonth) {
        this.filters.filMonth = today.getMonth() + 1
      }

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // Se usuário for um vendedor filtro pelo seu usuário ao entrar no sistema
        if (!this.showEmployeeFilter) {
          this.filters.filEmployee = this.$store.getters.userInfo.active_employee.id_employee
          this.firstFilter = false
        } else {
          // Senão filtro pelo primeiro vendedor da lista
          this.filters.filEmployee = this.sellerList[0].id
        }
      }

      this.filter.employee = this.filters.filEmployee
      this.filter.year = this.filters.filYear
      this.filter.quarter = this.filters.filQuarter
      this.filter.month = this.filters.filMonth

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    // Filter
    applyFilter () {
      this.seller.image = null

      // Period
      if (this.$refs.view_type_quarter.classList.contains('active')) {
        this.filters.filPeriodo = 'quarter'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      this.filters.filEmployee = this.$refs.employee ? parseInt(this.$refs.employee.value) : null
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filQuarter = this.$refs.quarter ? parseInt(this.$refs.quarter.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      /* Todo: Reload Charts */
      this.getReportSellerData()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filQuarter = this.getQuarter(today)
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'monthly'

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // Se usuário for um vendedor filtro pelo seu usuário ao entrar no sistema
        if (this.isSeller) {
          this.filters.filEmployee = this.$store.getters.userInfo.active_employee.id_employee
        } else {
          // Senão filtro pelo primeiro vendedor da lista
          this.filters.filEmployee = this.sellerList[0].id
        }
      }

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.getReportSellerData()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showNumber(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (thousandsSep !== '') {
        thousandsSep = thousandsSep || '.'
      }

      // eslint-disable-next-line
      if (number != parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showIntegerGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showInteger(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    // End Filter
    getReportSellerData () {
      let me = this
      this.isLoading = true
      if (this.$store.getters.userInfo) {
        EmployeeService.getSellers([]).then(res => {
          me.sellerList = res.data.data
          if (this.isDirector || this.isFinancial || this.isSuperAdmin || this.isComercialAdmin) {
            me.sellerList.unshift({ id: null, nome: 'TODOS' })
          }

          me.buildFilters()

          ReportService.getSellerQuarter(this.filter.employee, this.filter).then(res => {
            me.seller = res.data.seller
            me.reportData = res.data
            this.isLoading = false
          }).finally(function () {
            me.isLoading = false
          })
        })
      }
    },
    generateImage (value) {
      let baseUrl = process.env.VUE_APP_BaseURL + '/'

      let imageUrl = baseUrl + 'static/images/customers/no_photo.jpeg'
      if (value) {
        let fileId = value.split('id=')
        imageUrl = 'http://drive.google.com/uc?export=view&id=' + fileId[1]
      }

      return imageUrl
    },
    asset (value) {
      let baseUrl = process.env.VUE_APP_BaseURL + '/'

      return baseUrl + value
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      let filters = {
        'searchTerm': search
      }
      CustomerService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
      })
    },
    /**
     * DataTransform to display Quarter Progresss Chart Bar
     *
     * @param data  {Array}   unformatted chart data
     */
    barChartTransform (data) {
      let transformedGoal = data.map(x => (
        x.goal
        // x.total_goal
      ))

      let transformedRealized = data.map(x => (
        x.realized
        // x.total_realized
      ))

      let colors = []

      for (let i = 0; i < transformedRealized.length; i++) {
        if (transformedRealized[i] < transformedGoal[i]) {
          colors.push('#FFD863')
        } else {
          colors.push('#008f59')
        }
      }

      return [
        {
          data: transformedGoal,
          type: 'line',
          fill: false,
          borderWidth: 2,
          borderColor: '#84754E',
          backgroundColor: '#84754E'
        },
        {
          data: transformedRealized,
          fill: false,
          backgroundColor: colors
        }
      ]
    }
  },
  computed: {
    showNewChart () {
      if (this.reportData.summary && this.reportData.ano < 2021) {
        return false
      }
      return true
    },
    isDirector () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_DIRECTOR') !== -1) {
        return true
      }
      return false
    },
    isFinancial () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_FINANTIAL') !== -1) {
        return true
      }
      return false
    },
    isComercialAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_COMERCIAL_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSeller () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        if (userInfo.active_employee) {
          if (userInfo.active_employee.is_seller) {
            return true
          }
        }
      }
      return false
    },
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_COMERCIAL_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SALES_COORDINATOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_REPRESENTS_ALAN_AS_SELLER').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    totalFiles () {
      let total = 0

      let reportData = this.reportData
      if (reportData.details) {
        total = (reportData.details.fcl.import.boarded.files.current +
        reportData.details.fcl.export.boarded.files.current +
        reportData.summary.air.current +
        reportData.summary.maritime.lcl.current)
      }

      return total
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getReportSellerData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .card-body {
    padding: 0.5rem 1.8rem;
  }

  @media (max-width: 576px) {

    .card-body {
      padding: 0.8rem 0.2rem;
    }

    .gray-box {
      padding: 0.8rem 0.2rem;
    }
  }

  .dark-gray-box {

    background-color: #e3e2e1;

    border-radius: 0 0 38px 38px;
    margin-bottom: 10px;
    padding: 25px;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 1px;
  }

  .dark-gray-box .line {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 27px;
  }

  .label {
    padding-left: 47px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-position: center left;
    background-repeat: no-repeat;
    margin-left: 10px;
    font-size: 11px;
    font-weight: bold;

  }

  .label.air {
    background-image: url("/static/images/report/air_icon.png");
  }

  .label.ship {
    background-image: url("/static/images/report/ship_icon.png");
  }

  .label.lcl {
    background-image: url("/static/images/report/lcl_icon.png");
  }

  .label.fcl {
    background-image: url("/static/images/report/fcl_icon.png");
  }

  .label.insurance {
    background-image: url("/static/images/report/insurance_icon.png");
  }

  .label.green {
    background-image: url("/static/images/report/green_icon.png");
    padding-left: 20px;
  }

  .label.yellow {
    background-image: url("/static/images/report/yellow_icon.png");
    padding-left: 20px;
  }

  .label.red {
    background-image: url("/static/images/report/red_icon.png");
    padding-left: 20px;
  }

  .card-legend, .card-title {
    color: #84754E;
    text-align: center;
    font-size: 18px;
  }

  .card-title, .card-title b {
    margin-top: 45px;
    margin-bottom: 0;
    font-size: 19px;

  }

  .summary-box .message{
    font-weight: 200;
    font-size: 14px;
    font-family: pluto_sanscond_light;
  }

  .gray-box {
    background-color: #F3F1ED;
    border-radius: 38px 38px 0 0;
    margin-top: 82px;
  }

  /* Sellect Section */
  .row.seller-box {
    margin-bottom: -100px;
  }

  .seller {
    text-align: center;
    top: -100px;

  }

  .seller img {
    border: 10px solid #F3F1ED;
    border-radius: 148px;
    width: 148px;
    position: relative;
    overflow: hidden;
  }

  .seller h2 {
    margin: 12px 0;
    font-size: 25px;
  }

  .summary-box {
    margin-top: 39px;
  }

  /* Message box */
  .message-box {
    margin: 0 2.8rem;
    color: #84734C;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    .message-box {
      margin: 0 0.8rem;
    }
  }

  /* Details */
  .detail-box .indicator-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 50px 60px;
  }

  /* Details */
  .detail-box .content-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 20px 20px;
  }
  .detail-box .content-box .card-title {
    margin-top: 15px;
  }

  .detail-box.first .indicator-box {

    border-radius: 40px 40px 0 0;
    padding: 0 60px;
    padding-top: 50px;
  }

  .detail-box.middle .indicator-box {

    border-radius: 0 0 0 0;
    padding: 0 60px;
    padding-bottom: 65px;
  }

  .detail-box.first {
    margin-top: 70px;

  }
  .detail-box.last .indicator-box, .detail-box.simple .indicator-box {
    padding-bottom: 45px;

  }

  .detail-box.simple {
    margin-top: 70px;
  }

  .detail-box.simple .indicator-box {
    border-radius: 40px 40px 40px 40px;
  }

  /* Activities */

  .activities h3 {
    margin-top: 0;
    color: #84754E;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }

  .activities h3.message {
    margin-top: 45px;
    font-size: 19px;
    font-weight: bold;
  }

  .activities .card-title {
    text-align: center;
    font-size: 13px !important;
    font-weight: bold;
    color: #84754E;
  }

  .activities .icon {

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 55px;
  }

  .activities .icon.price {
    background-image: url("/static/images/report/price_icon.png");
  }

  .activities .icon.visits {
    background-image: url("/static/images/report/visits_icon.png");
  }

  .activities .icon.email {
    background-image: url("/static/images/report/email_icon.png");
  }

  .activities .icon.presentation {
    background-image: url("/static/images/report/presentations_icon.png");
  }

  .activities .icon.callPhones {
    background-image: url("/static/images/report/cellPhone_icon.png");
  }

  /* right operation / modal label */
  .detail-box .col-md-2 {
    padding: 0;
  }

  .detail-box .col-md-2 p {
    top: 113px;
    font-size: 26px;
    font-weight: 200;
    color: #84754E;
  }

  .detail-box .col-md-2 p {
    top: 197px;
    left: 0;
    padding-left: 58px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 23px;
    font-weight: 200;
    position: relative;
    background-image: url("/static/images/report/carret.png");
    background-position: -9px center;
    background-repeat: no-repeat;

  }

  .chart-label .medium {
    font-size: 10px
  }

  .card-legend .small {
    font-size: 11px !important;
  }

  .card-title.info {
    margin-top: 59px;
  }

  .card-title.center.info {
    margin-top: 134px;
  }

  .card-title.info, .card-title.info b, .card-legend.info {
    font-size: 15px;
  }
</style>
