<template>
  <div :style="{ width: (this.width)}" style="margin: 0 auto">
  <div :id="'bg_'+elementId" :style="{ width: (this.width), height: (this.height) }" style="margin: 0 auto" >
    <canvas :id="elementId" style="width: 100% !important;height: auto !important;"></canvas>
    <!--<div v-if="hasSlot()"  class="chart-label" :style="{top: '-'+(this.offset)+'px'}">-->
      <!--<slot></slot>-->
    <!--</div>-->

    <!--<div class="chart-label percent" :style="{'top': '-'+(this.offset)+'px'}">-->
      <!--{{ showNumber(percent) }}%-->
    <!--</div>-->

  </div>
  <div class="no-wrap" :style="{ width: (this.width), height: (parseInt(11) + 6) + 'px' }">
    <div>
      <div :style="{ fontSize: 11, width: (25+'%')}" style="display: inline-block; text-align: center">
        Q1
      </div>
      <div :style="{ fontSize: 11, width: (25+'%')}" style="display: inline-block; text-align: center">
        Q2
      </div>
      <div :style="{ fontSize: 11, width: (25+'%')}" style="display: inline-block; text-align: center">
        Q3
      </div>
      <div :style="{ fontSize: 11, width: (25+'%')}" style="display: inline-block; text-align: center">
        Q4
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Charts
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chart.js/dist/Chart.min.css'

// Por padrão label vem habilitada para todos os gráficos (Removendo para poder colocar apenas nos que desejar)
Chart.plugins.unregister(ChartDataLabels)

export default {
  inheritAttrs: false,
  name: 'BarChart',
  components: {
  },
  data () {
    return {
      refName: this.refInput || 'field_' + Math.random().toString().substr(2),
      percent: 0.0,
      chartData: [],
      chartObject: null,
      chartOptions: {}
    }
  },
  updated () {
    this.chartObject = this.buildChart()
  },
  methods: {
    calcPercent (value, total) {
      let percent = 0
      if (total === 0) {
        if (value <= 0) {
          percent = 0
        } else {
          percent = 100
        }
      } else {
        percent = value * 100 / total
      }

      return percent
    },
    buildChart () {
      let series = []
      let formatedData = []

      formatedData = this.formatData(this.value)

      series = formatedData
      this.chartData = series

      let MONTHS_SH = [
        (this.$i18n.t('date.month.Jan')).substring(0, 3),
        (this.$i18n.t('date.month.Feb')).substring(0, 3),
        (this.$i18n.t('date.month.Mar')).substring(0, 3),
        (this.$i18n.t('date.month.Apr')).substring(0, 3),
        (this.$i18n.t('date.month.May')).substring(0, 3),
        (this.$i18n.t('date.month.Jun')).substring(0, 3),
        (this.$i18n.t('date.month.Jul')).substring(0, 3),
        (this.$i18n.t('date.month.Aug')).substring(0, 3),
        (this.$i18n.t('date.month.Sep')).substring(0, 3),
        (this.$i18n.t('date.month.Oct')).substring(0, 3),
        (this.$i18n.t('date.month.Nov')).substring(0, 3),
        (this.$i18n.t('date.month.Dec')).substring(0, 3)
      ]

      this.chartOptions = {
        type: 'bar',
        data: {
          datasets: this.chartData,
          labels: MONTHS_SH
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            enabled: false,
            mode: 'index',
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem[0].datasetIndex].label

                if (label) {
                  return data['labels'][tooltipItem[0]['index']] + ' (' + label + ')'
                } else {
                  return data['labels'][tooltipItem[0]['index']]
                }
              },
              label: function (tooltipItem, data) {
                let currentColumn = data.datasets[tooltipItem.datasetIndex].attribute
                let currentStack = data.datasets[tooltipItem.datasetIndex].stack
                let valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                // Loop through all datasets to get the actual total of the index
                var total = 0
                for (var i = 0; i < data.datasets.length; i++) {
                  let dataSet = data.datasets[i]

                  if (dataSet.stack === currentStack) {
                    total += data.datasets[i].data[tooltipItem.index]
                  }
                }

                // If it is not the last dataset, you display it as you usually do
                if (data.datasets[tooltipItem.datasetIndex].total) {
                  if (total > 0) {
                    return [currentColumn + ' : ' + total]
                  } else {
                    return false
                  }
                } else { // .. else, you display the dataset and the total, using an array
                  if (valor > 0) {
                    return currentColumn + ' : ' + valor
                  } else {
                    return false
                  }
                }
              },
              afterLabel: function (tooltipItem, data) {
                /* var dataset = data['datasets'][0];
                  var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                  return '(' + percent + '%)'; */
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 14,
            titleSpacing: 10,
            titleMarginBottom: 10,
            bodySpacing: 4,
            titleFontColor: '#3f3f3f',
            bodyFontColor: '#333',
            bodyFontSize: 13,
            cornerRadius: 3,
            yPadding: 10,
            xPadding: 10,
            displayColors: true
          },
          elements: {
            line: {
              tension: 0.2
            },
            rectangle: {
              borderWidth: 2
            },
            point: {
              radius: 0
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          axes: {
            display: false
          },
          scales: {
            xAxes: [{
              display: false,
              stacked: true,
              // categoryPercentage: 0.90,
              // barPercentage: 1.0,
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              },
              scaleLabel: {
                display: false
                // labelString: this.$i18n.t('period.title')
              }
            }],
            yAxes: [{
              display: false,
              stacked: false,
              // categoryPercentage: 1.0,
              // barPercentage: 1.0,
              scaleLabel: {
                display: false
                // labelString: this.$i18n.t('quantity')
              }

            }]
          }
        }
      }
      let chartCtx = document.getElementById(this.elementId).getContext('2d')

      if (this.chartObject) {
        this.chartObject.destroy()
      }

      return new Chart(chartCtx, this.chartOptions)
    },
    // Filters
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      // eslint-disable-next-line
      if (number != parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    }
  },
  computed: {
    offset () {
      if (this.topOffset !== undefined) {
        return parseInt(this.height) - this.topOffset
      }

      return this.height / 2
    }
  },
  props: {
    elementId: {
      type: String,
      required: true
    },
    emptyColor: {
      type: String,
      required: false,
      default: '#F3F1ED'
    },
    // value
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    goal: {
      type: Number
    },
    topOffset: {
      type: Number,
      required: false
    },
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    percentMark: {
      type: Number,
      required: false,
      default: 100
    },
    formatData: {
      type: Function,
      required: false,
      default: (val) => {
        return val
      }
    }
  }
}
</script>

<style scoped>
  .chart-label {
    text-align: center;
    position: relative;
    z-index: 0;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    color: #84754E;
  }

  .no-wrap {
    border-top: 1px solid #ccc ;
  }

</style>
