<template>
  <div :class="classRoot">
    <div class="card-title" v-if="$slots.title">
      <slot name="title"></slot>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
    <div class="card-legend" v-if="$slots.legend">
      <slot name="legend"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    showBorder: {
      type: Boolean,
      default: true
    },
    classRoot: {
      type: [String, Object, Array],
      default: 'card-fullheight'
    }
  }
}
</script>

<style scoped>
  .card-black {
    background-color: #3D3D3D;
  }

</style>
