<template>
  <div class='col-12'>
    <div :id='id' class='chartdiv' ref='chartdiv'></div>
  </div>
</template>

<script>
// Charts
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'horizontal-gauge-chart',
  data () {
    return {
      id: null,
      container: null,
      chart: null,
      hand: null,
      hand2: null,
      polygonSeries: null,
      matchingGrade: null,
      valueAxis: null,
      // valueAxis2: null,
      chartMin: 0.0,
      chartMax: 350000.0
    }
  },
  props: {
    ranges: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            title: '0%',
            color: '#f04922',
            minValue: 0,
            maxValue: 100000.00
          },
          {
            title: '3%',
            color: '#fdae19',
            minValue: 100000.01,
            maxValue: 180000.00
          },
          {
            title: '5%',
            color: '#f3eb0c',
            minValue: 180000.01,
            maxValue: 240000.00
          },
          {
            title: '8%',
            color: '#b0d136',
            minValue: 240000.00,
            maxValue: 300000.00
          },
          {
            title: '13%',
            color: '#54b947',
            minValue: 300000.01,
            maxValue: 350000.00
          }
        ]
      }
    },
    value: {
      type: Number,
      required: true,
      default: () => {
        return 0.0
      }
    },
    colorType: {
      type: String,
      required: false,
      default: 'solid'
    },
    title: {
      type: String,
      required: false,
      default: 'Comissão %'
    }
  },
  watch: {
    ranges (newVal, oldVal) {
      let _this = this
      _this.updateChartMinMax(newVal)

      _this.valueAxis.axisRanges.setAll([])

      for (let i in newVal) {
        let range = newVal[i]
        _this.createRange(_this.valueAxis, range.minValue, range.maxValue, am4core.color(range.color), range.title, false)
        // _this.createRange(_this.valueAxis2, range.minValue, range.maxValue, am4core.color(range.color), range.title, true)
      }

      // _this.valueAxis2.axisRanges.setAll([])

      _this.valueAxis.min = _this.chartMin
      _this.valueAxis.max = _this.chartMax

      // _this.valueAxis2.min = _this.chartMin
      // _this.valueAxis2.max = _this.chartMax
    },
    value (newVal, oldVal) {
      let chartData = [{
        'category': this.title,
        'value': this.getFormatedValue(newVal)
      }]
      this.chart.data = chartData
    }
  },
  methods: {
    updateChartMinMax (ranges) {
      let newChartMax = null
      let newChartMin = null

      for (let i in ranges) {
        let item = ranges[i]
        if (newChartMin === null || item.minValue < newChartMin) {
          newChartMin = item.minValue
        }

        if (newChartMax === null || item.minValue > newChartMax) {
          newChartMax = item.maxValue
        }
      }

      this.chartMax = newChartMax
      this.chartMin = newChartMin
    },
    getFormatedValue (val) {
      let _this = this

      if (val <= _this.chartMin) {
        return _this.chartMin
      }

      if (val >= _this.chartMax) {
        return _this.chartMax
      }
      let b = val

      return b
    },
    lookUpGrade (lookupScore, grades) {
      // Only change code below this line
      for (var i = 0; i < grades.length; i++) {
        if (
          grades[i].minValue < lookupScore &&
          grades[i].maxValue >= lookupScore
        ) {
          return grades[i]
        }
      }
      return null
    },
    /* Create ranges */
    createRange (axis, from, to, color, title, label) {
      var range = axis.axisRanges.create()
      range.value = from
      range.endValue = to
      range.axisFill.fill = color
      range.axisFill.fillOpacity = 0.8
      range.grid.disabled = true
      range.label.inside = true
      range.label.text = title
      range.label.inside = true
      range.label.location = 0.5
      range.label.paddingBottom = 6
      range.label.inside = true

      if (label) {
        range.axisFill.fillOpacity = 0.0
        range.label.text = (to / 1000).toFixed(0) + 'k'
        range.label.location = 1
        range.label.paddingBottom = 32
        range.label.paddingRight = 47
      }
    }
  },
  created () {
    this.id = 'chart_' + this._uid
  },
  mounted () {
    let _this = this
    _this.updateChartMinMax(_this.ranges)

    let container = am4core.create(this.$refs.chartdiv, am4core.Container)
    container.width = am4core.percent(100)
    container.height = am4core.percent(100)
    container.layout = 'vertical'

    /* Create chart instance */
    var chart = container.createChild(am4charts.XYChart)
    chart.paddingRight = 25
    chart.resizable = true
    chart.data = [{
      'category': _this.title,
      'value': _this.getValue
    }]

    /* Create axes */
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'category'
    categoryAxis.renderer.minGridDistance = 10000000000000000
    categoryAxis.renderer.grid.template.disabled = true

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.grid.template.disabled = true
    valueAxis.min = _this.chartMin
    valueAxis.max = _this.chartMax
    valueAxis.strictMinMax = true
    valueAxis.renderer.baseGrid.disabled = true
    valueAxis.renderer.labels.template.disabled = true

    _this.valueAxis = valueAxis

    // var valueAxis2 = chart.xAxes.push(new am4charts.ValueAxis())
    // valueAxis2.renderer.grid.template.disabled = true
    // valueAxis2.min = _this.chartMin
    // valueAxis2.max = _this.chartMax
    // valueAxis2.strictMinMax = true
    // valueAxis2.renderer.baseGrid.disabled = true
    // valueAxis2.renderer.labels.template.disabled = true
    //
    // _this.valueAxis2 = valueAxis2

    /*
      In order to create separate background colors for each range of values,
      you have to create multiple axisRange objects each with their own fill color
    */
    if (_this.colorType === 'solid') {
      for (let i in _this.ranges) {
        let range = _this.ranges[i]
        _this.createRange(valueAxis, range.minValue, range.maxValue, am4core.color(range.color), range.title, false)
        // _this.createRange(valueAxis2, range.minValue, range.maxValue, am4core.color(range.color), range.title, true)
      }
      // for (let i = 0; i < 5; ++i) {
      //   _this.createRange(valueAxis, start, end, am4core.color(colors[i]))
      //   start += 20
      //   end += 20
      // }
    } else if (_this.colorType === 'gradient') {
      var gradient = new am4core.LinearGradient()
      for (let i in _this.ranges) {
        let range = _this.ranges[i]
        gradient.addColor(am4core.color(range.color))
      }
      for (let i = 0; i < 5; ++i) {
        // add each color that makes up the gradient

      }
      _this.createRange(valueAxis, _this.chartMin, _this.chartMax, gradient)
    }

    /* Create series */
    // var series = chart.series.push(new am4charts.ColumnSeries())
    // series.dataFields.valueX = 'value'
    // series.dataFields.categoryY = 'category'
    // series.columns.template.fill = am4core.color('#000')
    // series.columns.template.stroke = am4core.color('#fff')
    // series.columns.template.strokeWidth = 1
    // series.columns.template.strokeOpacity = 0.5
    // series.columns.template.height = am4core.percent(25)
    // series.tooltipText = '{value}'

    var series2 = chart.series.push(new am4charts.StepLineSeries())
    series2.dataFields.valueX = 'value'
    series2.dataFields.categoryY = 'category'
    series2.strokeWidth = 0
    series2.noRisers = true
    series2.startLocation = 0.0
    series2.endLocation = 1.0

    series2.tooltipText = '{valueX}'
    series2.stroke = am4core.color('#000')

    let bullet = series2.bullets.push(new am4charts.Bullet())
    let triangle = bullet.createChild(am4core.Triangle)
    triangle.width = 5
    triangle.height = 25
    triangle.fill = am4core.color('#000')
    triangle.horizontalCenter = 'middle'
    triangle.verticalCenter = 'middle'
    triangle.paddingTop = 3

    // chart.cursor = new am4charts.XYCursor()
    // chart.cursor.lineX.disabled = true
    // chart.cursor.lineY.disabled = true
    // chart.cursor.xRange.disabled = true

    valueAxis.cursorTooltipEnabled = false
    chart.arrangeTooltips = false

    _this.chart = chart
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  computed: {
    getValue () {
      return this.getFormatedValue(this.value)
    },
    chartValue () {
      let colorPosition = 0
      let colorized = this.value.map(item => {
        if (item.value) {
          item.color = this.colors[colorPosition]
          item.fill = am4core.color(this.colors[colorPosition])
          colorPosition++
        }

        return item
      })

      return colorized
    },
    legend () {
      let colorized = this.chartValue.filter(item => {
        return item.hasOwnProperty('id')
      })

      return colorized
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  .chartdiv {
    width: 100%;
    height: 60px;
  }

  .badge-point{
      height: 10px;
      width: 10px;
      margin-right: 6px;
  }
</style>
